<template>
  <label class="form-label">
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'FormLabel',
}
</script>

<style scoped lang="scss">
.form-label {
  font-family: LuciferSans;
  letter-spacing: 0.06em;
  font-size: 0.8125rem;
}
</style>
